import React, {useEffect, useState}                           from "react";
import {FC}                                                   from "react";
import {addDays, addMonths, format, getDaysInMonth, subYears} from "date-fns";
import {WheelPicker}                                          from "./WheelPicker";

type DateObject = {
    day: number | undefined,
    month: number | undefined,
    year: number | undefined
};

// ##### helper start #####
const isUndefined = (value) => {
    return value === undefined;
}

const yearMatch = (year: string) => (date: Date): boolean => {
    return year === date.getFullYear();
}
// ##### helper end #####

const DAY_FORMAT   = 'dd';
const MONTH_FORMAT = 'LLL';
const YEAR_FORMAT  = 'yyyy';

const DAY_COUNT   = 31;
const MONTH_COUNT = 12;
const YEAR_COUNT  = 125;

const days   = Array.from({length: DAY_COUNT}, (e, i) => ({value: addDays(new Date(2000, 0, 1), i)}));
const months = Array.from({length: MONTH_COUNT}, (e, i) => ({value: addMonths(new Date(2000, 0, 1), i)}));
const years  = Array.from({length: YEAR_COUNT}, (e, i) => ({value: subYears(new Date(), i)}));

const dayOptions = days.map((day) => ({
    ...day,
    key:   day.value,
    label: format(day.value, DAY_FORMAT)
}));

const monthOptions = months.map((month) => ({
    ...month,
    key:   month.value,
    label: undefined,
    getLabel: () => format(month.value, MONTH_FORMAT)
}));

const yearOptions = years.map((year) => ({
    value: year.value,
    key:   year.value,
    label: format(year.value, YEAR_FORMAT)
}));

type DateWidths = {
    day: number,
    month: number,
    year: number,
}

type DateWheelPickerProps = {
    value: DateObject,
    onChange: (value: DateObject) => void,
    defaultIndex?: number,
    focusDate: Date,
    containerHeight: number,
    itemHeight: number,
    widths: DateWidths,
    updateOnScroll?: boolean
};

export const DateWheelPicker: FC<DateWheelPickerProps> = (props) => {
    const {
              value,
              onChange,
              defaultIndex = -1,
              focusDate,
              itemHeight,
              widths,
              containerHeight,
              updateOnScroll = false,
              ...extraProps
          } = props;

    const getYearIndex = (testYear, yearOptions) => {
        if (isUndefined(testYear)) {
            return defaultIndex;
        }

        const index = yearOptions.findIndex(
            (option) => yearMatch(testYear)(option.value)
        );

        return index !== -1 ? index : defaultIndex;
    }

    // day index -- day is 1 based so we need to -1 to get the index
    const [day, setDay]     = useState(!isUndefined(value.day) ? value.day - 1 : defaultIndex);
    // month index
    const [month, setMonth] = useState(!isUndefined(value.month) ? value.month : defaultIndex);
    // index into year options
    const [year, setYear]   = useState(getYearIndex(value.year, yearOptions));

    const length = getDaysInMonth(new Date(yearOptions[year]?.value.getFullYear() || 2000, month))

    useEffect(() => {
        if (day >= length) {
            updateDay(length - 1);
        }
    }, [length]);

    const updateDay = (dayIndex: number) => {
        setDay(dayIndex);
        onChange(({
            ...value,
            day: dayIndex + 1
        }));
    }

    const updateMonth = (monthIndex: number) => {
        setMonth(monthIndex);
        onChange(({
            ...value,
            month: monthIndex
        }));
    }

    const updateYear = (yearIndex: number) => {
        setYear(yearIndex);
        onChange(({
            ...value,
            year: yearOptions[yearIndex].value.getFullYear()
        }));
    }

    return (
        <div
            {...extraProps}
            className={extraProps.className + ' date-wheel-picker'}
        >
            <WheelPicker
                value={day}
                initialFocusIndex={!isUndefined(value.day) ? day : (focusDate.getDate() - 1)}
                options={dayOptions.slice(0, length)}
                onChange={updateDay}
                containerHeight={containerHeight}
                itemHeight={itemHeight}
                width={widths.day}
                updateOnScroll={updateOnScroll}
            />
            <WheelPicker
                value={month}
                initialFocusIndex={!isUndefined(value.month) ? month : focusDate.getMonth()}
                options={monthOptions}
                onChange={updateMonth}
                containerHeight={containerHeight}
                itemHeight={itemHeight}
                width={widths.month}
                updateOnScroll={updateOnScroll}
            />
            <WheelPicker
                value={year}
                initialFocusIndex={!isUndefined(value.year) ? year : getYearIndex(focusDate.getFullYear(), yearOptions)}
                options={yearOptions}
                onChange={updateYear}
                containerHeight={containerHeight}
                itemHeight={itemHeight}
                width={widths.year}
                updateOnScroll={updateOnScroll}
            />
        </div>
    );
}
